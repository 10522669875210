/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { ReactQueryConfigProvider } from 'react-query'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { config } from '@fortawesome/fontawesome-svg-core'
import Router from 'next/router'
import NProgress from 'nprogress'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'

import CommonHead from '~/components/CommonHead'
import * as gtag from '~/lib/gtag'
import theme from '~/styles/theme'

import '@fortawesome/fontawesome-svg-core/styles.css'
import 'nprogress/nprogress.css'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

config.autoAddCss = false

const queryConfig = {
  queries: {
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
  },
}

/**
 * App
 */

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    Bugsnag.start({
      apiKey: 'ffa05d52e00802d3e2e007b434303202',
      plugins: [new BugsnagPluginReact()],
    })
  }, [])

  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url)
    }

    // Hack to get real browser viewport height
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ThemeProvider theme={theme}>
        <CommonHead />
        <Normalize />
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </ReactQueryConfigProvider>
  )
}

App.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
}

/**
 * StyledComponents
 */

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${props => props.theme.colors.surface};
    font-display: swap;
    font-family: ${props =>
      props.theme.fonts.primary}, -apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Helvetica, sans-serif;
  }

  #nprogress .bar {
    background: ${props => props.theme.colors.primary}
  }
  
  #nprogress .peg {
    box-shadow: 0 0 2px ${props => props.theme.colors.primary}, 0 0 5px ${props => props.theme.colors.primary};
  }

  #nprogress .spinner {
    display: none;
  }

`

/**
 * Exports
 */

export default App
