/**
 * Theme
 */

const breakpoints = {
  xs: 1,
  sm: 36, // 576px
  md: 48, // 768px
  lg: 62, // 992px
  xl: 75, // 1200px
}

const theme = {
  awesomegrid: {
    mediaQuery: 'only screen',
    breakpoints,
  },
  colors: {
    primary: '#D23527',
    primaryTinted: '#d15045',
    textOverImage: '#fff',
    text: '#111',
    secondaryText: '#575656',
    secondaryTextHover: '#8f8f8f',
    metaText: '#696969',
    tagText: '#383636',
    tagBackground: '#cacaca',
    surface: '#fafafa',
    separator: '#cacaca',
    background: '#111',
    backgroundTinted: '#494949',
    primaryShadow: '#710d09',
    feature: '#eee',
  },
  extras: {
    mobileHeaderHeight: '60px',
    desktopHeaderHeight: '77px',
    mobileFooterHeight: '420px',
    desktopFooterHeight: '79px',
  },
  fonts: {
    primary: 'Nunito Sans',
    secondary: 'Lora',
    monospace: 'Inconsolata',
  },
}

export default theme
