import React from 'react'
import Head from 'next/head'

/**
 * Constants
 */

const DEFAULT_IMAGE = '/images/default_image_by_krys_amon_unsplash.jpg'

/**
 * CommonHead
 */

const CommonHead = () => {
  const title = process.env.NEXT_PUBLIC_SITE_NAME
  const description = 'Lo más destacado de la escena de la música electrónica en Latinoamérica y el Mundo.'

  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Nunito+Sans:wght@400;600;700;800&display=block"
        rel="stylesheet"
      />
      <meta name="theme-color" content="#D23527" />

      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Default Photo by Krys Amon from Unsplash.com */}
      <meta property="og:image" content={DEFAULT_IMAGE} key="ogImage" />
      <meta property="twitter:image" content={DEFAULT_IMAGE} key="twitterCardImage" />

      <meta property="og:type" content="website" key="ogType" />
      <meta property="og:title" content={title} key="ogTitle" />
      <meta property="og:description" content={description} key="ogDescription" />

      <meta property="twitter:card" content="summary_large_image" key="twitterCardSummary" />
      <meta property="twitter:title" content={title} key="twitterCardTitle" />
      <meta property="twitter:description" content={description} key="twitterCardDescription" />

      <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
    </Head>
  )
}

/**
 * Exports
 */

export default CommonHead
